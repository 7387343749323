.reviewsContainer
  display: flex
  gap: 20px
  margin: 20px 0

  @media (max-width: 768px)
    flex-direction: column
    gap: 10px

.review
  width: 33.33%
  display: flex
  border: 1px solid #ddd
  padding: 20px
  border-radius: 10px
  background-color: #fff

  @media (max-width: 768px)
    width: 100%

.reviewHeader
  display: flex
  align-items: center
  gap: 10px

.userImage
  width: 50px
  height: 50px
  margin-right: 10px
  border-radius: 10px

.userName
  color: #007bff
  font-weight: bold
  font-size: 14px

.userLocation
  color: #888
  font-size: 12px

.reviewText
  margin-bottom: 10px
  margin-top: 5px
  font-size: 14px
  line-height: 1.3
  color: #656565

.reviewFooter
  display: flex
  align-items: center
  gap: 8px

.likeButton,
.replyButton
  pointer-events: none
  background: none
  color: #007bff
  border: none
  border-radius: 5px
  cursor: pointer
  display: flex
  align-items: center
  padding: 0

.likeCount
  margin-left: 5px
  color: #007bff
  font-size: 12px
  border-radius: 10px

.dot
  width: 4px
  height: 4px
  background-color: #007bff
  border-radius: 50%
