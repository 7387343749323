.cross-sells
  margin-top: 20px
  text-align: left

  &--opc
    padding-left: 20px
    padding-right: 20px

    .cross-sells-item
      padding: 10px
      border-width: 1px
      border-left: none
      border-right: none
      border-radius: 0
      border-color: #eee

  @media (max-width: 430px)
    margin-left: -20px
    margin-right: -20px

  &__title
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 20px
    font-weight: bold
    font-size: 20px
    text-align: center

    .fa-arrow-alt-down
      margin: 0 6px
      color: #2b89cc

.cross-sells-item
  position: relative
  margin-bottom: 20px
  padding: 10px
  padding-top: 15px
  border: 2px solid rgb(184, 184, 184)
  border-radius: 5px
  background-color: #fff

.cross-sells-item-left-badge
  position: absolute
  top: -5px
  left: -5px
  padding-left: 4px
  padding-right: 15px
  padding-top: 4px
  padding-bottom: 4px
  background-color: #00d61d
  color: white
  font-size: 15px
  border-radius: 0 0 30px 0
  line-height: 1

  b
    display: block

  span
    font-size: 12px

.cross-sells-item-right-badge
  position: absolute
  right: 10px
  top: -8px
  font-size: 13px
  padding: 2px 4px
  color: white
  background-color: red
  text-transform: uppercase
  font-weight: bold
  border-radius: 4px

  .fa-exclamation-triangle
    color: yellow

.cross-sells-item-inner
  display: flex
  justify-content: space-between

.cross-sells-item
  &__img
    display: flex
    justify-content: center
    flex: 1
    margin-right: 10px
    padding-top: 15px

    img
      min-width: 50px
      max-height: 90px
      align-self: center

  &__description
    flex: 4
    padding-right: 10px

    h4
      margin-bottom: 14px
      font-size: 18px
      font-weight: bold

    li
      display: flex
      margin-bottom: 4px
      font-size: 14px
      text-transform: capitalize
      line-height: 1.2

      .fa-check
        margin-right: 4px
        color: #00d61d
        font-size: 20px

    p
      font-size: 13px

  &__price
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    flex: 1
    text-align: center
    cursor: pointer

    h4
      margin-bottom: 14px
      font-size: 16px
      font-weight: bold

    img
      height: 40px
      margin-bottom: 6px

    s
      color: red

    p, s
      font-size: 14px

  .cross-sells-item__price-checkmark
    display: none

  &.active
    .cross-sells-item__price-checkmark
      display: block

    .cross-sells-item__price-empty-checkmark
      display: none

  .cross-sells-item__price-empty-checkmark
    display: block

.cross-sells-variant-selector
  display: flex
  align-items: center
  margin-top: 10px

  select
    margin-left: 5px
    padding: 5px
    border-radius: 4px
    font-size: 16px

// new design
.nd-cross-sells-item
  .cross-sells-item-left-badge
    top: 0
    left: 0
    background-color: #bd0e14
    font-weight: bold
    text-align: center

    span
      font-size: inherit

  .cross-sells-item
    &__img
      width: 100px
      flex: none
      flex-direction: column
      margin-top: 10px

      @media (max-width: 768px)
        width: 70px

    &__description
      padding: 0 10px

      h4
        font-size: 20px

      li
        margin-bottom: 0

    &__price
      line-height: 1.4

      img
        border: 2px solid #000
        border-radius: 10px
        overflow: hidden

      s
        color: #7e7e7e
        font-size: 18px

      p
        font-weight: bold
        font-size: 18px

  .cross-sells-variant-selector
    flex-direction: column

    p
      margin-bottom: 3px

    select
      width: 90px
      margin-left: 10px
      padding: 3px
      font-size: 14px
      text-overflow: ellipsis

  .cross-sells-item-right-badge
    position: static
    background-color: transparent
    color: #bd0e14
    white-space: nowrap
    text-transform: capitalize
    font-weight: normal
    font-size: 16px

  .cross-sells-item-inner
    display: flex
    align-items: center

.nd-cross-sells__title
  justify-content: space-between
  text-align: left
  font-weight: normal

  @media (max-width: 768px)
    padding-left: 10px
    font-size: 16px

  p
    &:last-child
      margin-right: 30px
      margin-bottom: -10px
      font-size: 14px
      text-align: center
