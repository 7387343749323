.variantSelect
  width: 100%
  margin-top: 20px
  text-align: center
  border: 1px solid #e0e0e0
  border-radius: 5px

  &__title
    margin-top: 10px
    margin-bottom: 14px
    font-weight: bold
    font-size: 17px

  &__item
    display: inline-flex
    align-items: center
    margin: 0 10px
    padding: 10px
    cursor: pointer
    font-size: 16px
    transition: all 0.2s ease

    @media (max-width: 768px)
      padding: 0px
      font-size: 16px

    i
      margin-right: 6px
      font-size: 16px

    span
      line-height: 1.2

    &.active
      color: #4c99c5
      font-weight: bold

.variantSelectItems
  display: flex
  justify-content: center
  flex-wrap: wrap

  @media (max-width: 768px)
    padding: 10px 0
